import SandwichLineIcon from '@glise/icons/SandwichLineIcon';
import styles from './MobileHeader.module.scss';
import ArrowIcon from '@glise/icons/ArrowIcon';
import FullLogoIcon from '@glise/icons/FullLogoIcon';
import Link from 'next/link';
import Button from '@glise/components/common/Button/Button';
import cn from 'classnames';
import { useState } from 'react';
import XMarkIcon from '@glise/icons/XMarkIcon';
import useTranslation from 'next-translate/useTranslation';
import LanguageSwitcher from '@glise/components/common/LanguageSwitcher/LanguageSwitcher';

function MobileHeader() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.style.overflow = 'hidden';
  //     document.documentElement.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.overflow = 'auto';
  //     document.documentElement.style.overflow = 'auto';
  //   }
  // }, [isOpen]);

  const { t, lang } = useTranslation('header');

  return (
    <div className={cn(styles.MobileHeaderContainer)}>
      <div
        className={cn(styles.MobileHeaderWrapper, isOpen && styles.OpenMenu)}
      >
        <Link href="/" className={styles.MobileLogo} aria-label={'Glise logo'}>
          <FullLogoIcon />
        </Link>
        <div className={styles.ButtonsWrapper}>
          <div
            className={styles.SandwichButton}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? <XMarkIcon /> : <SandwichLineIcon />}
          </div>
        </div>
      </div>
      <div className={cn(styles.ModalContainer, isOpen && styles.Open)}>
        <Link
          href="/pricing"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className={styles.NavLink}
          aria-label={t('nav.pricing')}
        >
          {t('nav.pricing')}
        </Link>
        <Link
          href="/contact"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className={styles.NavLink}
          aria-label={t('nav.contact')}
        >
          {t('nav.contact')}
        </Link>
        <div className={styles.ActionsWrapper}>
          <LanguageSwitcher />
          <Link
            href={'/contact'}
            className={styles.ActionLink}
            aria-label={t('actions.bookDemo')}
          >
            <Button
              color="blue"
              className={styles.TrialButton}
              endIcon={<ArrowIcon />}
              breakpoint={'xl'}
              aria-label={t('actions.bookDemo')}
            >
              {t('actions.bookDemo')}
            </Button>
          </Link>
        </div>
        {/*<Link href={`https://app.${currentHost}/login`} target={'_blank'}>*/}
        {/*  <Button*/}
        {/*    className={styles.LoginButton}*/}
        {/*    color="transparent"*/}
        {/*    endIcon={<ArrowIcon />}*/}
        {/*  >*/}
        {/*    Log In*/}
        {/*  </Button>*/}
        {/*</Link>*/}
      </div>
    </div>
  );
}

export default MobileHeader;
