
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '@glise/styles/main.scss';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { ToastBar, Toaster } from 'react-hot-toast';
import { ThemeProvider } from 'next-themes';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Layout from '@glise/bricks/Layout/Layout';
import useTranslation from 'next-translate/useTranslation';
import { createTheme, CssBaseline, ThemeProvider as MuiThemeProvider, } from '@mui/material';
import { Readex_Pro } from 'next/font/google';
export const GOOGLE_CAPTCHA = process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA ?? '';
export const primaryFont = Readex_Pro({
    subsets: ['latin'],
    variable: '--font-primary',
});
const theme = createTheme({
    typography: {
        fontFamily: primaryFont.style.fontFamily,
    },
});
function App({ Component, pageProps }: AppProps) {
    const { t } = useTranslation('metainfo');
    return (<>
      <Head>
        <title>{t('gliseContent.title')}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
      </Head>
      <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_CAPTCHA} scriptProps={{
            async: false,
            defer: false,
            appendTo: 'head', // Optional: append script to 'head' or 'body'
        }}>
        <style jsx global>{`
          input,
          textarea,
          select,
          button {
            font-family: ${primaryFont.style.fontFamily};
          }
        `}</style>
        <ThemeProvider defaultTheme={'dark'}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Layout>
              <Component {...pageProps}/>
            </Layout>
            <Toaster toastOptions={{
            position: 'top-right',
        }} containerClassName={'hot-toast'}>
              {(t) => <ToastBar toast={t}/>}
            </Toaster>
          </MuiThemeProvider>
        </ThemeProvider>
      </GoogleReCaptchaProvider>
    </>);
}
const __Next_Translate__Page__193abcf8220__ = App;

    export default __appWithI18n(__Next_Translate__Page__193abcf8220__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  